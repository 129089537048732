body {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glass-container {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.glass-input {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
}

.glass-button {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
